input {
  margin: 1.5rem 0;
  padding: 0.5rem;
}
.clear-button {
  display: block;
}
.search-grab {
  display: flex;
  justify-content: center;
  align-items: center;
}
