ul {
  /* padding: 5rem; */
  list-style: none;
  display: flex;
}

@font-face {
  font-family: "Astoria-Roman";
  src: url("../public/fonts/Astoria-Roman.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Astoria-Roman", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
}

.app-title {
  font-weight: 200;
  font-size: 2.4rem;
  text-align: center;
  color: rgb(175, 182, 180);
}
.id-list {
  overflow-x: auto;
  display: flex;
  max-width: 90%;
  gap: 0.5rem;
  padding: 4rem 5rem;
  padding-top: 6rem;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
