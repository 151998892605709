.productID {
  font-size: 1.3rem;
  font-weight: bold;
  height: 55px;
  width: 55px;

  cursor: grab;
  font-style: normal;
  filter: drop-shadow(0px 3px 4px #696767);
  background-color: #2cc9b4;
  color: rgb(255, 255, 255);
}

.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: 600;
    position: relative;
  }
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #4cc1c5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;

  position: absolute;
  min-width: 150px;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
